// const baseURL = 'http://10.12.41.17:8080'
const baseURL = 'http://172.16.50.238:7020/manage'
// 获取列表接口
const getFormProcessListURL = `${API_CONFIG.butlerBaseURL}activiti/actFormProcessdef/list`
// 查询数据接口
const queryURL = `${API_CONFIG.butlerBaseURL}activiti/actFormProcessdef/queryById`
// 提交数据接口
const updateURL = `${API_CONFIG.butlerBaseURL}activiti/actFormProcessdef/edit`
// 新增数据接口
const createURL = `${API_CONFIG.butlerBaseURL}activiti/actFormProcessdef/add`
// 删除数据接口
const deleteURL = `${API_CONFIG.butlerBaseURL}activiti/actFormProcessdef/delete`
// 删除数据接口
const batchDeleteURL = `${API_CONFIG.butlerBaseURL}activiti/actFormProcessdef/deleteBatch`
// 获取流程信息
const getProcessByKeyURL = `${API_CONFIG.butlerBaseURL}activiti_process/listByKey`

export {
    getFormProcessListURL,
    queryURL,
    updateURL,
    createURL,
    deleteURL,
    batchDeleteURL,
    getProcessByKeyURL
}