<template>
  <div class="AppInfoList-wrapper">
    <list
      ref="list"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="true"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add" @click="create" />
      </template>
      <template #searchSlot>
        <v-input label="表单类型" v-model="searchParams.formType" placeholder="请输入" />
        <v-input label="实体表" v-model="searchParams.businessTable" placeholder="请输入" />
        
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" permission="update" @click="edit(scope.row)" />
        <v-button text="删除" type="text" permission="delete" @click="handleDelete(scope.row)" />
      </template>
      <template #batchSlot="scope">
        <v-button text="批量删除"
                  permission="delete"
                  :disabled="scope.selectedData.ids.length === 0"
                  @click="batch(scope.selectedData)" />
      </template>
    </list>
  </div>
</template>

<script>
import { getFormProcessListURL, deleteURL, batchDeleteURL } from './api'
import { formStatusOps, formStatusMap } from './map'

export default {
  name: 'FormProcessRelavList',
  data () {
    return {
      searchUrl: getFormProcessListURL,
      maxDate: new Date(),
      
      searchParams: {
        formType: '',
        businessTable: ''
      },
      headers: [
        {
          prop: 'formType',
          label: '单据类型'
        },
        {
          prop: 'processKey',
          label: '流程标识'
        },
        {
          prop: 'businessTable',
          label: '数据库实体表'
        },
        {
          prop: 'createTime',
          label: '创建时间'
        }
      ]
    }
  },
  computed: {
    visible () {
      const userType = this.$store.state.userInfo.userType
      return userType === '106'
    }
  },
  methods: {
    create () {
      if (this.visible) {
        this.$router.push({
          name: 'formManageDetail'
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    edit (row) {
      if (this.visible) {
        this.$router.push({
          name: 'formManageDetail',
          query: {
            id: row.id
          }
        })
      } else {
        this.$message.error('用户无操作权限!')
      }
    },
    async handleDelete (row) {
        const result = await this.$confirm('确认删除？', {
            title: '提示'
        })
        if(result) {
            var that = this
            let params = {
                id: row.id
            }
            that.$axios.delete(deleteURL, { params: params }).then((res) => {
                if (res.status === 100) {
                    that.$message.success("删除成功")
                    that.$refs.list.searchData()
                } else {
                    that.$message.warning(res.msg)
                }
            })
        }
       
    },
    async batch (selected) {
      let result = await this.$confirm('确认删除？', {
        title: '提示'
      })
      if (result) {
        // 操作日志添加操作对象参数,组装规则：敏感词-分类
        console.log(selected.ids)
        const params = {
          ids: selected.ids.join(',')
        }
        const { status, data } = await this.$axios.delete(batchDeleteURL, { params })
        if (status === 100) {
          this.$message.success('删除成功')
          this.$refs.list.searchData()
        }
      }
    },

  }
}
</script>
