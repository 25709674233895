var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "AppInfoList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          searchUrl: _vm.searchUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
          isMultiSelect: true,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "表单类型", placeholder: "请输入" },
                  model: {
                    value: _vm.searchParams.formType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "formType", $$v)
                    },
                    expression: "searchParams.formType",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "实体表", placeholder: "请输入" },
                  model: {
                    value: _vm.searchParams.businessTable,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "businessTable", $$v)
                    },
                    expression: "searchParams.businessTable",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: { text: "编辑", type: "text", permission: "update" },
                  on: {
                    click: function ($event) {
                      return _vm.edit(scope.row)
                    },
                  },
                }),
                _c("v-button", {
                  attrs: { text: "删除", type: "text", permission: "delete" },
                  on: {
                    click: function ($event) {
                      return _vm.handleDelete(scope.row)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "batchSlot",
            fn: function (scope) {
              return [
                _c("v-button", {
                  attrs: {
                    text: "批量删除",
                    permission: "delete",
                    disabled: scope.selectedData.ids.length === 0,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.batch(scope.selectedData)
                    },
                  },
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }